import React, { useEffect, useState } from 'react';
import Header from '../directives/header';
import Footer from '../directives/footer';
import config from '../config/config';
import axios from 'axios';

function Marketplace() {

  const [BackgroundData, setBackgroundData] = useState([])
  const [ClothesData, setClothesData] = useState([])
  const [EarringData, setEarringData] = useState([])
  const [EyesData, setEyesData] = useState([])
  const [FurData, setFurData] = useState([])
  const [HatData, setHatData] = useState([])
  const [MouthData, setMouthData] = useState([])


  useEffect(() => {
    var acc = document.getElementsByClassName("accordion");
    var i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
    getBackgroundAPI()
    getClothesAPI()
    getEarringAPI()
    getEyesAPI()
    getFurAPI()
    getHatAPI()
    getMouthAPI()
  }, [])

  //===========================================  Background API  ==============================================

  const getBackgroundAPI = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}background`,
    })
      .then(result => {
        console.log(result);
        if (result.data.response) {
          setBackgroundData(result.data.response)
        }
      }).catch(err => {
      });
  }

  //===========================================  Clothes API  ==============================================

  const getClothesAPI = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}clothes`,
    })
      .then(result => {
        console.log(result);
        if (result.data.response) {
          setClothesData(result.data.response)
        }
      }).catch(err => {
      });
  }

  //===========================================  Earring API  ==============================================

  const getEarringAPI = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}earring`,
    })
      .then(result => {
        console.log(result);
        if (result.data.response) {
          setEarringData(result.data.response)
        }
      }).catch(err => {
      });
  }

  //===========================================  Eyes API  ==============================================

  const getEyesAPI = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}eyes`,
    })
      .then(result => {
        console.log(result);
        if (result.data.response) {
          setEyesData(result.data.response)
        }
      }).catch(err => {
      });
  }

  //===========================================  Eyes API  ==============================================

  const getFurAPI = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}fur`,
    })
      .then(result => {
        console.log(result);
        if (result.data.response) {
          setFurData(result.data.response)
        }
      }).catch(err => {
      });
  }

  //===========================================  Eyes API  ==============================================

  const getHatAPI = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}hat`,
    })
      .then(result => {
        console.log(result);
        if (result.data.response) {
          setHatData(result.data.response)
        }
      }).catch(err => {
      });
  }

  //===========================================  Eyes API  ==============================================

  const getMouthAPI = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}mouth`,
    })
      .then(result => {
        console.log(result);
        if (result.data.response) {
          setMouthData(result.data.response)
        }
      }).catch(err => {
      });
  }

  const dataClick = async (id, id1) => {
    console.log(id, id1);
  }


  return (

    <>
      <Header />
      <div className="main-content">

        <section id="characters" className="characters content-section mt-5 latest-articles text-light">
          <br />
          <div className="container mt-0 pt-0">
            <div className="row">
              <div className="col-sm-3 col-md-3 col-lg-3 mx-auto mb-7 md-lg-0">
                <div className="filter">
                  <button className="accordion ">Background</button>
                  <div className="panel">
                    <ul>
                      {BackgroundData.map(item => (
                        <li className="linkData" onClick={e => dataClick('background', item.id)}>{item.name}</li>
                      ))}
                    </ul>
                  </div>
                  <button className="accordion mt-2">Clothes</button>
                  <div className="panel">
                    <ul>
                      {ClothesData.map(item => (
                        <li className="linkData" onClick={e => dataClick('clothes', item.id)}>{item.name}</li>
                      ))}
                    </ul>
                  </div>
                  <button className="accordion mt-2">Earning</button>
                  <div className="panel">
                    <ul>
                      {EarringData.map(item => (
                        <li className="linkData" onClick={e => dataClick('earring', item.id)}>{item.name}</li>
                      ))}
                    </ul>
                  </div>
                  <button className="accordion mt-2">Eyes</button>
                  <div className="panel">
                    <ul>
                      {EyesData.map(item => (
                        <li className="linkData" onClick={e => dataClick('eyes', item.id)}>{item.name}</li>
                      ))}
                    </ul>
                  </div>
                  <button className="accordion mt-2">Fur</button>
                  <div className="panel">
                    <ul>
                      {FurData.map(item => (
                        <li className="linkData" onClick={e => dataClick('fur', item.id)}>{item.name}</li>
                      ))}
                    </ul>
                  </div>
                  <button className="accordion mt-2">Hat</button>
                  <div className="panel">
                    <ul>
                      {HatData.map(item => (
                        <li className="linkData" onClick={e => dataClick('hat', item.id)}>{item.name}</li>
                      ))}
                    </ul>
                  </div>
                  <button className="accordion mt-2">Mouth</button>
                  <div className="panel">
                    <ul>
                      {MouthData.map(item => (
                        <li className="linkData" onClick={e => dataClick('mouth', item.id)}>{item.name}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-9 col-md-9 col-lg-9 mx-auto mb-7 md-lg-0">
                <div className="marketplace">
                  <div className="row">
                    <div className="col-sm-4 mb-5">
                      <div className="box-market">
                        <img src="assets/img/monkey3.gif" />
                        <h4>Art</h4>
                      </div>
                    </div>
                    <div className="col-sm-4 mb-5">
                      <div className="box-market">
                        <img src="assets/img/monkey4.gif" />
                        <h4>NFT</h4>
                      </div>
                    </div>
                    <div className="col-sm-4 mb-5">
                      <div className="box-market">
                        <img src="assets/img/monkey.png" />
                        <h4>MUSIC</h4>
                      </div>
                    </div>
                    <div className="col-sm-4 mb-5">
                      <div className="box-market">
                        <img src="assets/img/monkey-music.gif" />
                        <h4>Art</h4>
                      </div>
                    </div>
                    <div className="col-sm-4 mb-5">
                      <div className="box-market">
                        <img src="assets/img/monkey9.png" />
                        <h4>NFT</h4>
                      </div>
                    </div>
                    <div className="col-sm-4 mb-5">
                      <div className="box-market">
                        <img src="assets/img/monkey8.gif" />
                        <h4>MUSIC</h4>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="box-market">
                        <img src="assets/img/monkey3.gif" />
                        <h4>Art</h4>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="box-market">
                        <img src="assets/img/monkey4.gif" />
                        <h4>NFT</h4>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="box-market">
                        <img src="assets/img/monkey.png" />
                        <h4>MUSIC</h4>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>

      <Footer />

    </>
  );
}

export default Marketplace;