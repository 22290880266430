import React, { Component } from 'react';
import config from '../config/config'
import { Link } from 'react-router-dom';


const Footer = (props) => {


  return (

       <>
       {/* footer */}
{/* <footer className="footer footer-dark bg-dark py-9">
  <div className="container">
    <div className="row gutters-y">
      <div className="col-6 col-lg-3">
        <a href="#" className="logo d-block mb-4"><img src="assets/img/content/logo-nft.png" alt="Crypto Bar" className="logo-dark" /></a>
        <p>Crypto Bar is a online store where you can find digital goods at the best prices.</p>
        <div className="social-buttons">
          <a className="social-twitter" href="#"><i className="fab fa-twitter" /></a>
          <a className="social-dribbble" href="#"><i className="fab fa-dribbble" /></a>
          <a className="social-instagram" href="#"><i className="fab fa-instagram" /></a>
        </div>
      </div>
      <div className="col-6 col-lg-2">
        <h6 className="text-uppercase fw-600 mb-4">About</h6>
        <div className="nav flex-column">
          <a className="nav-link" href="#">Our team</a>
          <a className="nav-link" href="#">Careers</a>
          <a className="nav-link" href="#">Cookie Policy</a>
          <a className="nav-link" href="#">Privacy Policy</a>
        </div>
      </div>
      <div className="col-6 col-lg-2">
        <h6 className="text-uppercase fw-600 mb-4">Community</h6>
        <div className="nav flex-column">
          <a className="nav-link" href="#">Forum</a>
          <a className="nav-link" href="#">Blog</a>
          <a className="nav-link" href="#">News</a>
          <a className="nav-link" href="#">Team</a>
        </div>
      </div>
      <div className="col-6 col-lg-2">
        <h6 className="text-uppercase fw-600 mb-4">Help</h6>
        <div className="nav flex-column">
          <a className="nav-link" href="#">Contact Us</a>
          <a className="nav-link" href="#">Support</a>
          <a className="nav-link" href="#">Terms &amp; conditions</a>
          <a className="nav-link" href="#">Refund policy</a>
        </div>
      </div>
      <div className="col col-lg-3 order-lg-last">
        <div className="mb-2">
          <h6 className="text-uppercase fw-600 mb-4">Ways to pay</h6>
          <div className="text-light lead-5">
            <a href="store.html" className="mr-2"><i className="fab fa-cc-paypal" /></a>
            <a href="store.html" className="mr-2"><i className="fab fa-cc-visa" /></a>
            <a href="store.html" className="mr-2"><i className="fab fa-cc-amazon-pay" /></a>
            <a href="store.html" className="mr-2"><i className="fab fa-cc-stripe" /></a>
            <a href="store.html" className="mr-2"><i className="fab fa-cc-jcb" /></a>
          </div>
        </div>
        <div>
          <h6 className="mb-2">Reviews</h6>
          <div className="text-warning lead-1">
            <i className="fas fa-star" />
            <i className="fas fa-star" />
            <i className="fas fa-star" />
            <i className="fas fa-star" />
            <i className="fas fa-star text-secondary" />
          </div>
        </div>
      </div>
    </div>
  </div>
</footer> */}
{/* /.footer */}
        <footer className="footer mobile-footer footer-dark py-0">
          <div className=" py-5">
            <div className="container">
              <div className="row gutters-y align-items-center">
                <div className="col-lg-4 col-12 mtc">
                  <div className="nav-bold-style text-uppercase">
                    <ul>
                      <li><a className="nav-link py-1 pb-sm-0 " href="terms_and_conditions.html">Terms &amp; Conditions</a></li>
                      <li><Link className="nav-link py-1 pb-sm-0 " to={`${config.baseUrl}provenance`}> Provenance</Link></li>
                   
                    </ul>
                   
                  </div>
                </div>
                <div className="col-lg-4 text-center">
                  <div className="footer-logo">
                    <img src="assets/img/content/logo-nft.png" />
                  </div>
                </div>
                <div className="col-lg-4 text-right mtc">
                  <div className="social-buttons lead-1">
                    <ul>
                      <li>
                        <p className="nav-link py-1 pb-sm-0  mb-0" href="#">VERIFIED SMART CONTRACT ADDRESS:</p>
                      </li>
                      <li>
                        <div className="nav-link py-1 pb-sm-0 ">
                          <div className="wordbreak "><a href="">0xBC4CA0EdA7647A8aB7C2061c2Eh37hkjdl</a></div>
                        </div>
                      </li>
                    </ul>
                    <a className="social-facebook" href="#"><img src="assets/img/Discord-Logo.png" width="25px" style={{"filter":"brightness(0) invert(1)","object-fit":"contain","margin-top" : "-3px"}} /></a>
                     
                    <a className="social-twitter" target="_blank" href="https://www.facebook.com/cryptobardestin/?ref=page_internal"><i className="fab fa-facebook" /></a>
                    <a className="social-twitter" href="https://twitter.com/CryptoBarDestin" target="_blank"><i className="fab fa-twitter" /></a>
                    <a className="social-instagram" href="https://www.instagram.com/cryptobardestin/" target="_blank"><i className="fab fa-instagram" /></a>
                    {/* <a className="social-dribbble" href="#"><i className="fab fa-opensea"><img src="assets/img/ship.png" style={{width: '21px', marginTop: '-3px'}} /></i></a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-dark-end py-6 text-center">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <span className="ls-1 fw-500">Copyright © 2021 Cryptobar. All Rights Reserved.</span>
                </div>
              </div>
            </div>
          </div>
        </footer>
</>
        );
    }

    export default Footer;   
