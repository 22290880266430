// import React, { Component } from 'react';


import React, { useEffect, useState } from 'react';
import Header from '../directives/header';
import Footer from '../directives/footer';
import config from '../config/config';
import axios from 'axios';

import 'react-accessible-accordion/dist/fancy-example.css';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

// import Swiper core and required modules
import SwiperCore, {
  EffectCube, Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([EffectCube, Pagination]);



function Home() {

  const [content, setContent] = useState([])
  const [content1, setContent1] = useState([])
  const [content2, setContent2] = useState([])
  const [content22, setContent22] = useState([])
  const [content3, setContent3] = useState([])
  const [content4, setContent4] = useState([])
  const [content5, setContent5] = useState([])
  const [content6, setContent6] = useState([])
  const [content7, setContent7] = useState([])
  const [content8, setContent8] = useState([])

  useEffect(async () => {
    getSection1API()
    getAboutAPI()
    getSection2API()
    getSection3API()
    getSection4API()
    getSection5API()
    getSection6API()
    getRoadmapAPI()
    getTeamAPI()
    getFaqAPI()
  }, [])

  //==========================================  GET Section1  ================================  

  const getSection1API = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getSection1`,
    }).then(response => {
      if (response.data.success === true) {
        setContent(response)
      }
    })
  }

  //==========================================  GET About  ================================  

  const getAboutAPI = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getAbout`,
    }).then(response => {
      if (response.data.success === true) {
        setContent1(response)
      }
    })
  }

  //==========================================  GET Section2  ================================  

  const getSection2API = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getSection2`,
    }).then(response => {
      if (response.data.success === true) {
        setContent22(response)
      }
    })
  }

  //==========================================  GET Section3  ================================  

  const getSection3API = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getSection3`,
    }).then(response => {
      if (response.data.success === true) {
        setContent2(response)
      }
    })
  }

  //==========================================  GET Section4  ================================  

  const getSection4API = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getSection4`,
    }).then(response => {
      if (response.data.success === true) {
        setContent3(response)
      }
    })
  }

  //==========================================  GET Section5  ================================  

  const getSection5API = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getSection5`,
    }).then(response => {
      if (response.data.success === true) {
        setContent4(response)
      }
    })
  }

  //==========================================  GET Section6  ================================  

  const getSection6API = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getSection6`,
    }).then(response => {
      if (response.data.success === true) {
        setContent5(response)
      }
    })
  }

  //==========================================  GET Roadmap  ================================  

  const getRoadmapAPI = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getRoadmap`,
    }).then(response => {
      if (response.data.success === true) {
        setContent6(response)
      }
    })
  }

  //==========================================  GET Team  ================================  

  const getTeamAPI = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getteam`,
    }).then(response => {
      if (response.data.success === true) {
        setContent7(response.data.response)
      }
    })
  }

  //==========================================  GET Faq  ================================  

  const getFaqAPI = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}faqlist`,
    }).then(response => {
      if (response.data.success === true) {
        setContent8(response.data.response)
      }
    })
  }


  return (

    <>

      <Header />
      <header id="header" className="header h-fullscreen  text-light">
        <div className="bubble-wrap" style={{ position: "absolute" }}>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
        </div>
        <div className="media-container parallax-window" data-parallax="scroll" data-image-src />
        <div className="overlay pe-n bg-dark_A-20" />
        <div className="overlay d-flex align-items-center">
          <div className="container  mt-8 pt-5">
            <div className="row align-items-center">
              <div className="col-lg-2  ">
                {/* <lottie-player id="monkey_lottie" src="https://assets2.lottiefiles.com/packages/lf20_q7uarxsb.json" background="transparent" speed={1} style={{ width: '512px', height: '512px' }} loop autoPlay /> */}
                {/* <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_f8swhg5f.json" background="transparent" speed={1} style={{ width: '500px', height: '400px' }} loop autoPlay /> */}
                {/* <lottie-player id="dice_lottie" src="https://assets2.lottiefiles.com/packages/lf20_chkytin8.json" background="transparent" speed={1} style={{width: '300px', height: '300px'}} loop autoPlay /> */}


              </div>
              <div className="col-lg-8  banner">
                {/* <h4>MINT JANUARY 15th, 5:00pm EST</h4> */}


                <h2 dangerouslySetInnerHTML={{ __html: content.data?.response[0].heading1 }}>

                  {/* <span className="theme-color" data-blast="color">"Massive Real World Utility, </span>Rare & Legendary
                    Art for all you  <span className="theme-color" data-blast="color">bar fly’s </span> out there"!
                    <div className="highlight">Pub & Grub Built On The Blockchain </div> */}
                </h2>
                <div className="row">

                  <p dangerouslySetInnerHTML={{ __html: content.data?.response[0].heading2 }}></p>
                  <div className="col-sm-12">


                  </div>
                  {/* <div className="col-lg-2"></div> */}
                  <div className="col-lg-12">
                    <div className="banner-btns d-flex flex-wrap " id="ipad_version"> 
                      <a href="https://discord.com/" target="_blank" className="btn btn-primary text-white btn-lg" data-blast="bgColor"><span>Join to Discord</span> </a>
                      &nbsp;&nbsp; <a href="https://whitepaper.cryptobarnft.com/" target="_blank" className="btn btn-outline-light btn-lg"><span>Whitepaper</span> </a>
                    </div>
                  </div>
                  {/* <div className="col-lg-3"></div> */}


                </div>

                {/* <img src="assets/img/content/game-logo.png" alt="Game Logo"> */}
              </div>
              <div className="col-lg-2 ">
                {/* <lottie-player id="monkey_lottie" src="https://assets2.lottiefiles.com/packages/lf20_q7uarxsb.json" background="transparent" speed={1} style={{ width: '512px', height: '512px' }} loop autoPlay /> */}
                {/* <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_f8swhg5f.json" background="transparent" speed={1} style={{ width: '500px', height: '400px' }} loop autoPlay /> */}
                {/* <lottie-player id="dice_lottie" src="https://assets2.lottiefiles.com/packages/lf20_chkytin8.json" background="transparent" speed={1} style={{width: '300px', height: '300px'}} loop autoPlay /> */}


              </div>
            </div>
          </div>
        </div>
        <div className="fadeIn ad-800ms">
          <div className="down-arrow floating-arrow absolute-center-X">
            <span className="fas fa-chevron-down" />
          </div>
        </div>
      </header>
      <main className="main-content">
      <div className="partner-logo text-center">
          <div className="mobile-hide logo-1" style={{ color: '#fff' }}>From the Co-Founder of &nbsp;<img src={`${config.imageUrl}${content22.data?.response[0].image1}`} />&nbsp; in collaboration with <img src={`${config.imageUrl}${content22.data?.response[0].image2}`} />&nbsp; &amp; former <img src={`${config.imageUrl}${content22.data?.response[0].image3}`} /> and <img src={`${config.imageUrl}${content22.data?.response[0].image4}`} />&nbsp; #1 rapper, writer, and producer.</div>
          <div className=" mobile-logo logo-1">From the Co-Founder of <br />
            <img src={`${config.imageUrl}${content22.data?.response[0].image1}`} /><br />
            in collaboration with<br /> <img src={`${config.imageUrl}${content22.data?.response[0].image2}`} /><br />
            &amp; former #1 rapper, writer, and producer.<br /> <img src={`${config.imageUrl}${content22.data?.response[0].image3}`} />  <img src={`${config.imageUrl}${content22.data?.response[0].image4}`} /></div>
          {/* <div class="logo-1">
               Same Day Pros Home Service &nbsp;
                 <img src="assets/img/same-day-pros-home-services-logo.svg">
               </div>
               <div class="logo-1">
               Penthouse Club Baltimore &nbsp;
                 <img src="assets/img/penthouse-club-baltimore-logo.png">
               </div>
               <div class="logo-1">
               Roc-Fella Records &nbsp;
                 <img src="assets/img/rocafella.png">
               </div>
               <div class="logo-1">
               Billboard Music &nbsp;
                 <img src="assets/img/billboard.png">
               </div> */}
        </div>
        {/* <div className="partner-logo text-center"><div className="mobile-hide logo-1" style={{ color: '#fff' }}>From the Co-Founder of &nbsp;<img src="https://oddfellowsnft.com:6019/api/uploads/image-1641376018937.png" />&nbsp; in collaboration with <img src="https://oddfellowsnft.com:6019/api/uploads/image-1641375938461.png" />&nbsp; &amp; former <img src="https://oddfellowsnft.com:6019/api/uploads/image-1641376064669.png" /> and <img src="https://oddfellowsnft.com:6019/api/uploads/image-1641375938480.png" />&nbsp; #1 rapper, writer, and producer.</div><div className=" mobile-logo logo-1">From the Co-Founder of <br /><img src="https://oddfellowsnft.com:6019/api/uploads/image-1641376018937.png" /><br />in collaboration with<br /> <img src="https://oddfellowsnft.com:6019/api/uploads/image-1641375938461.png" /><br />&amp; former #1 rapper, writer, and producer.<br /> <img src="https://oddfellowsnft.com:6019/api/uploads/image-1641376064669.png" />  <img src="https://oddfellowsnft.com:6019/api/uploads/image-1641375938480.png" /></div></div> */}
        {/* Start Content Area */}
        <section id="about" className="content-section position-relative text-light text-center parallax-window" data-parallax="scroll" data-image-src="#" >
          <div className="container position-relative">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 mx-auto text-left">
                <div dangerouslySetInnerHTML={{ __html: content1.data?.response[0].about }}>
                  {/* <h2 className="ls-1 ">Rarity & Traits, Specs</h2>
                  
                  <p className="lead-1">Crypto Bar is a collection of 10,000 NFT’s on the Ethereum blockchain.<br /><br />

                    Each Crypto Bar NFT is unique and programmatically generated from over 100 possible traits, including bar decor, your favorite crypto currency signage, moon globes, and more. All Crypto Bar NFT’s are funky, but some are more rare and more funky than others, including 11 legendary 1 of 1’s by the legend himself, Oschino Vasquez. These ultra rare masterpieces from Oschino combined with 100 rare beer mugs that give you free beer for life plus other mind blowing savings that are connected to your Crypto Bar NFT!
                    <br />These hand drawn legends include Snoop Dog, Elvis, and Johnny Cash just to name a few all partying up in the funky enclave of crypto bar.<br />Crypto Bar NFT’s are stored as ERC-721 tokens on the Ethereum blockchain and hosted on IPFS. (See Record and Proof.) Purchasing a Crypto Bar NFT costs 0.099 ETH.
                    <br />To access members-only areas such as the Merch Store, and future integrations, Crypto Bar token holders will need to be signed into their MetaMask Wallet.<br />
                    To receive your token holders insane discounts you will need to show your token in your wallet on every visit to Crypto Bar.
                  </p> */}
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-5 mx-auto mb-7 md-lg-0">
                <div className="common-container">
                  <div className="row">
                    <div className="pb-2 pr-2 col-6">
                      {content1.data?.response[0].image1 === null || content1.data?.response[0].image1 === '' || content1.data?.response[0].image1 === undefined
                        ?
                        <img src='assets/img/content/mon_1.jpg' className="img-fluid" />
                        :
                        <img src={`${config.imageUrl}${content1.data?.response[0].image1}`} alt="image" className="img-fluid" />}

                    </div>
                    <div className="pb-2 pl-2 col-6">
                      {content1.data?.response[0].image2 === null || content1.data?.response[0].image2 === '' || content1.data?.response[0].image2 === undefined
                        ?
                        <img src='assets/img/content/mon_4.jpg' className="img-fluid" />
                        :
                        <img src={`${config.imageUrl}${content1.data?.response[0].image2}`} alt="image" className="img-fluid" />}
                    </div>
                  </div>
                  <div className="row">
                    <div className="pt-2 pr-2 col-6">
                      {content1.data?.response[0].image3 === null || content1.data?.response[0].image3 === '' || content1.data?.response[0].image3 === undefined
                        ?
                        <img src='assets/img/content/mon_9.jpg' className="img-fluid" />
                        :
                        <img src={`${config.imageUrl}${content1.data?.response[0].image3}`} alt="image" className="img-fluid" />}
                    </div>
                    <div className="pt-2 pl-2 col-6">
                      {content1.data?.response[0].image4 === null || content1.data?.response[0].image4 === '' || content1.data?.response[0].image4 === undefined
                        ?
                        <img src='assets/img/content/mon_14.jpg' className="img-fluid" />
                        :
                        <img src={`${config.imageUrl}${content1.data?.response[0].image4}`} alt="image" className="img-fluid" />}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        {/* /.End Content Area */}

        <section className="">
          <div className="container">
            <div className="bg-light-grey">
              <div className="row">
                <div className="col-sm-4">
                  <lottie-player id="monkey_lottie" src="https://assets2.lottiefiles.com/packages/lf20_q7uarxsb.json" background="transparent" speed={1} style={{ width: '400px', height: '400px' }} loop autoPlay />
                </div>
                <div className="col-sm-8">
                  <h2 dangerouslySetInnerHTML={{ __html: content2.data?.response[0].heading1 }}>


                  </h2>
                  <h2 className="ls-1 "> </h2>
                  {/* <hr className="w-10 border-warning border-top-2 o-90"> */}
                  <p className="lead-1" dangerouslySetInnerHTML={{ __html: content2.data?.response[0].heading2 }}></p>

                </div>

              </div>
            </div>

          </div>


        </section>
        {/* /.End Content Area */}


        {/* Start Content Area */}
        {/* Start Content Area */}
        <section id="about" className="content-section position-relative text-light text-center parallax-window" data-parallax="scroll" data-image-src="#" >
          <div className="container position-relative">
            <header className=" text-center mb-5">
              <h2 className="ls-1" dangerouslySetInnerHTML={{ __html: content3.data?.response[0].mainheading }}></h2>
              <hr className="w-10 border-warning border-top-2 o-90" />
              <p className="" dangerouslySetInnerHTML={{ __html: content3.data?.response[0].heading }}></p>
            </header>
            <div className="row mb-5">

              <div className="col-sm-6 col-md-6 col-lg-6 col-12 mx-auto mb-7 md-lg-0">
                <div className="cyrpto_bar_box">
                  <p className="lead-1" dangerouslySetInnerHTML={{ __html: content3.data?.response[0].text1 }}></p>


                </div>

                <div className="cyrpto_bar_box">
                  <p className="lead-1" dangerouslySetInnerHTML={{ __html: content3.data?.response[0].text2 }}></p>


                </div>
                <div className="cyrpto_bar_box">
                  <p className="lead-1" dangerouslySetInnerHTML={{ __html: content3.data?.response[0].text3 }}></p>


                </div>
                <div className="cyrpto_bar_box">
                  <p className="lead-1" dangerouslySetInnerHTML={{ __html: content3.data?.response[0].text4 }}></p>


                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-12 mx-auto mb-7 md-lg-0">
                <div className="common-container">
                  {/* <lottie-player id="building_lotties" src="https://assets5.lottiefiles.com/packages/lf20_1IBhTm.json" background="transparent" speed={1} style={{ width: '450px', height: '312px' }} loop autoPlay /> */}
                  {/* <lottie-player src="https://assets2.lottiefiles.com/packages/lf20_433lxcje.json" background="transparent" speed={1} style={{width: '512px', height: '512px'}} loop autoPlay /> */}
                  <lottie-player id="rocket_crypto" src="https://assets5.lottiefiles.com/packages/lf20_8wuout7s.json" background="transparent" speed={1} style={{ width: '600px', height: '600px' }} loop autoPlay />
                  {/* <lottie-player src="https://assets2.lottiefiles.com/packages/lf20_t5dvuh23.json" background="transparent" speed={1} style={{width: '300px', height: '300px'}} loop autoPlay /> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="">
          <div className="container">
            <div className="bg-light-grey">
              <div className="row">
                <div className="col-sm-4">
                  <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_pzbtwwgr.json" background="transparent" speed={1} style={{ width: '300px', height: '300px' }} loop autoPlay />
                  {/* <lottie-player id="monkey_flash" src="https://assets3.lottiefiles.com/packages/lf20_2XTLtH.json" background="transparent" speed={1} style={{width: '300px', height: '300px'}} loop autoPlay /> */}
                </div>
                <div className="col-sm-8">
                  <h2 dangerouslySetInnerHTML={{ __html: content4.data?.response[0].heading }}></h2>
                  <h2 className="ls-1 "> </h2>
                  {/* <hr className="w-10 border-warning border-top-2 o-90"> */}
                  <p className="lead-1" dangerouslySetInnerHTML={{ __html: content4.data?.response[0].text }}></p>

                </div>

              </div>
            </div>

          </div>


        </section>


        <section id="about" className="content-section position-relative text-light text-center parallax-window" data-parallax="scroll" data-image-src="#" >
          <div className="container position-relative">
            <div className="row">

              <div className="col-sm-6 col-md-6 col-lg-6 mx-auto text-left">
                <div>
                  <h2 className="ls-1 " dangerouslySetInnerHTML={{ __html: content5.data?.response[0].heading }}></h2>
                  {/* <hr className="w-10 border-warning border-top-2 o-90"> */}
                  <p className="lead-1" dangerouslySetInnerHTML={{ __html: content5.data?.response[0].text }}></p>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-5 mx-auto mb-7 md-lg-0">
                <div className="common-container">
                  <div className="row">
                    <div className="pb-2 pr-2 col-6">
                      {content5.data?.response[0].image1 === null || content5.data?.response[0].image1 === '' || content5.data?.response[0].image1 === undefined
                        ?
                        <img src='assets/img/content/mon_7.jpg' className="img-fluid" />
                        :
                        <img src={`${config.imageUrl}${content5.data?.response[0].image1}`} alt="image" className="img-fluid" />}
                    </div>
                    <div className="pb-2 pl-2 col-6">
                      {content5.data?.response[0].image2 === null || content5.data?.response[0].image2 === '' || content5.data?.response[0].image2 === undefined
                        ?
                        <img src='assets/img/content/mon_13.jpg' className="img-fluid" />
                        :
                        <img src={`${config.imageUrl}${content5.data?.response[0].image2}`} alt="image" className="img-fluid" />}

                    </div>
                  </div>
                  <div className="row">
                    <div className="pt-2 pr-2 col-6">
                      {content5.data?.response[0].image3 === null || content5.data?.response[0].image3 === '' || content5.data?.response[0].image3 === undefined
                        ?
                        <img src='assets/img/content/mon_9.jpg' className="img-fluid" />
                        :
                        <img src={`${config.imageUrl}${content5.data?.response[0].image3}`} alt="image" className="img-fluid" />}
                    </div>
                    <div className="pt-2 pl-2 col-6">
                      {content5.data?.response[0].image4 === null || content5.data?.response[0].image4 === '' || content5.data?.response[0].image4 === undefined
                        ?
                        <img src='assets/img/content/mon_11.jpg' className="img-fluid" />
                        :
                        <img src={`${config.imageUrl}${content5.data?.response[0].image4}`} alt="image" className="img-fluid" />}
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>

        <section id="roadmap" className="content-section position-relative latest-articles text-light text-center parallax-window">
          <div className="container" >
            <header className=" text-center mb-5">
              <h2 className="">Roadmap </h2>
              <hr className="w-10 border-warning border-top-2 o-90" />
              <p className="lead-1 text-center" dangerouslySetInnerHTML={{ __html: content6.data?.response[0].heading }}></p>
            </header>
            <div className="row">
              <div className="col-md-12">
                <div className="main-timeline4">
                  <div className="timeline">
                    <a href="#" className="timeline-content">
                      <span className="year">{content6.data?.response[0].date1}</span>
                      <div className="inner-content">
                        <h3 className="title">{content6.data?.response[0].title1}</h3>
                        <p className="description">
                          {content6.data?.response[0].description1}

                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="timeline">
                    <a href="#" className="timeline-content">
                      <span className="year">{content6.data?.response[0].date2}</span>
                      <div className="inner-content">
                        <h3 className="title">{content6.data?.response[0].title2}</h3>
                        <p className="description">
                          {content6.data?.response[0].description2}

                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="timeline">
                    <a href="#" className="timeline-content">
                      <span className="year">{content6.data?.response[0].date3}</span>
                      <div className="inner-content">
                        <h3 className="title">{content6.data?.response[0].title3}</h3>
                        <p className="description">
                          {content6.data?.response[0].description3}
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="timeline">
                    <a href="#" className="timeline-content">
                      <span className="year">{content6.data?.response[0].date4}</span>
                      <div className="inner-content">
                        <h3 className="title">{content6.data?.response[0].title4}</h3>
                        <p className="description">
                          {content6.data?.response[0].description4}

                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="timeline">
                    <a href="#" className="timeline-content">
                      <span className="year">{content6.data?.response[0].date5}</span>
                      <div className="inner-content">
                        <h3 className="title">{content6.data?.response[0].title5}</h3>
                        <p className="description">
                          {content6.data?.response[0].description5}


                        </p>
                      </div>
                    </a>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </section>




        {/* /.End Content Area */}

        {/* Start Content Area */}
        <section id="team" className="content-section team-section latest-articles position-relative text-center text-light parallax-window " data-parallax="scroll" data-image-src="#" >
          <div className="container position-relative">
            <header className="section-header">
              <h2 className="text-light ">Our Team</h2>
              <hr className="w-10 border-warning border-top-2 o-90" />
            </header>
            <div className="">
              <div className="">

                {/* ********* <3 ********* EIGHT ********* <3 ********* */}
                <ul className="eight">
                  {content7.map(item => {
                    return (<li className="transition">
                      <div className="wrapper">
                        <span className="transition">
                          <h3 className="transition">
                            {item.member}<em>{item.designation}</em></h3>
                          <div className="image-container transition">
                            <div className="creation">
                              <div className="front">
                                <div className="cont"> <i className="details">
                                  {item.member_pic === null || item.member_pic === '' || item.member_pic === undefined
                                    ?
                                    <img src='assets/img/avatar/1.jpg' className="icon-circle transition" />
                                    :
                                    <img src={`${config.imageUrl}${item.member_pic}`} alt="image" className="icon-circle transition" />}
                                </i> </div>
                              </div>
                              <div className="back">
                                <div className="cont">


                                  <i className="details">
                                    {item.member_pic === null || item.member_pic === '' || item.member_pic === undefined
                                      ?
                                      <img src='assets/img/avatar/1.jpg' />
                                      :
                                      <img src={`${config.imageUrl}${item.member_pic}`} alt="image" />}
                                  </i> </div>
                              </div>
                            </div>
                          </div>
                        </span>
                        <ul className="social transition">
                          <div className="social-buttons lead-1">
                            {item.facebook ?
                              <a className="social-facebook" href={item.facebook} target="_blank"><i className="fab fa-facebook" /></a>
                              :
                              ''
                            }
                            {item.twitter ?
                              <a className="social-twitter" href={item.twitter} target="_blank"><i className="fab fa-twitter" /></a>
                              : ''}
                            {item.instagram ?
                              <a className="social-instagram" href={item.instagram} target="_blank"><i className="fab fa-instagram" /></a>
                              : ''}

                            {item.linkedin ?
                              <a className="social-dribbble" href={item.linkedin} target="_blank"><i className="fab fa-linkedin" /></a>
                              : ''}

                            {item.spotify ?
                              <a className="social-instagram" href={item.spotify} target="_blank"><img src="assets/img/spotify.png" width="20px" style={{ marginTop: '-6px' }} /></a> : ''}

                            {item.music ?
                              <a className="social-instagram" href={item.music} target="_blank"><img src="assets/img/apple-music.png" width="20px" style={{ marginTop: '-6px' }} /></a> : ''}

                            {item.youtube ?
                              <a className="social-dribbble" target="_blank" href={item.youtube}><i className="fab fa-youtube"></i></a> : ''}
                          </div>
                          {/* <li className="transition"><a href={item?.twitter} target="_blank"><img src="http://dooreight.com/codepen/people/twitter.svg" /></a></li>
                          <li className="transition"><a href={item?.behance} target="_blank"><img src="http://dooreight.com/codepen/people/behance.svg" /></a></li>
                          <li className="transition"><a href={item?.facebook} target="_blank"><img src="http://dooreight.com/codepen/people/facebook.svg" /></a></li>
                          <li className="transition"><a href={item?.skype} target="_blank"><img src="http://dooreight.com/codepen/people/skype.svg" /></a></li> */}
                        </ul>
                        <div className="arrow" />
                      </div>
                    </li>)

                  })}


                </ul>

              </div>

            </div>
            {/* <div className="row">
              <div className="col-md-4 mb-7 mb-md-0">
                <a href="team.html">
                </a><article className="card article-post bg-dark_A-40 border border-secondary py-6 px-5"><a href="team.html">
                  <div className="position-relative overflow-hidden br-n   article-image ar-1_1 w-70 mx-auto  border border-secondary mb-3" style={{ backgroundImage: 'url(assets/img/avatar/Jeremy_Dunn.jpg)', backgroundSize: '100% 100%' }} />
                </a><figure><a href="team.html">
                </a><figcaption><a href="team.html">
                </a><h5 className="text-light mt-1 mb-0"><a href="team.html" /><a href="#" className="text-light">Jeremy Dunn</a></h5>
                      <div className="social-buttons lead-1">
                          <a className="social-facebook" target="_blank" href="https://www.facebook.com/jeremiah.m.dunn/"><i className="fab fa-facebook" /></a>
                          <a className="social-twitter" target="_blank" href="https://twitter.com/jeremiah_dunn"><i className="fab fa-twitter" /></a>
                          <a className="social-instagram" target="_blank" href="https://www.instagram.com/jeremiahdunn/"><i className="fab fa-instagram" /></a>
                          <a className="social-dribbble" target="_blank" href="  https://www.linkedin.com/in/jeremiahdunn/"><i className="fab fa-linkedin" /></a>
                      </div>
                    
                    </figcaption>
                  </figure>
                </article>
              </div>
              <div className="col-md-4 mb-7 mb-md-0">
                <a href="team.html">
                </a><article className="card article-post bg-dark_A-40 border border-secondary py-6 px-5"><a href="team.html">
                  <div className="position-relative overflow-hidden br-n   article-image ar-1_1 w-70 mx-auto  border border-secondary mb-3" style={{ backgroundImage: 'url(assets/img/oschino_kids.jpg)', backgroundSize: '100% 100%' }} />
                </a><figure><a href="team.html">
                </a><figcaption><a href="team.html">
                </a><h5 className="text-light mt-1 mb-0"><a href="team.html" /><a href="#" className="text-light">Oschino Vasquez</a></h5>
                      <div className="social-buttons lead-1">

                        <a className="social-instagram" target="_blank" href=" https://www.instagram.com/oschinovasquez1/"><i className="fab fa-instagram" /></a>
                        <a className="social-instagram" target="_blank" href=" https://open.spotify.com/artist/59h9wM4IPZZKUcMGiMaCJn?si=IpIPkYg4T16Pav0WrrEXAg"><img src="assets/img/spotify.png" width="20px" style={{"margin-top":"-6px"}} /></a>
                        <a className="social-instagram" target="_blank" href=" https://music.apple.com/us/artist/oschino/2091128"><img src="assets/img/apple-music.png" width="20px" style={{"margin-top":"-6px"}} /></a>
                        <a className="social-dribbble" target="_blank" href="https://www.youtube.com/c/OschinoVasquez"><i className="fab fa-youtube" /></a>

                      </div>
               
                    </figcaption>
                  </figure>
                </article>
              </div>
              <div className="col-md-4">
                <a href="team.html">
                </a><article className="card article-post bg-dark_A-40 border border-secondary py-6 px-5"><a href="team.html">
                  <div className="position-relative overflow-hidden br-n  article-image ar-1_1 w-70 mx-auto  border border-secondary mb-3" style={{ backgroundImage: 'url(assets/img/avatar/team3.jpg)', backgroundSize: '100% 100%' }} />
                </a><figure><a href="team.html">
                </a><figcaption><a href="team.html">
                </a><h5 className="text-light mt-1 mb-0"><a href="team.html" /><a href="#" className="text-light">Redouane Belhadi</a></h5>
                      <div className="social-buttons lead-1">
                      <a className="social-instagram" target="_blank" href="https://music.amazon.com/artists/B09J17N2M6/redouane-belhadi"><img src="assets/img/apple-music.png" width="20px" style={{"margin-top":"-6px"}} /></a>
                      <a className="social-instagram" target="_blank" href="https://www.instagram.com/redouane_belhadi/"><i className="fab fa-instagram" /></a>
                      
                      </div>
                      
                    </figcaption>
                  </figure>
                </article>
              </div>
            </div> */}
          </div>
        </section>
        {/* /.End Content Area */}
        {/* Start Content Area */}

        <section id="faq" className="content-section team-section latest-articles position-relative text-center text-light parallax-window" data-parallax="scroll" data-image-src="#" >
          <div className="container position-relative">
            <header className="section-header">
              <h2 className="text-light ">Frequently Ask Questions?</h2>
              <hr className="w-10 border-warning border-top-2 o-90" />
            </header>
            <div className="">
              <div className="">
                {/* ********* <3 ********* EIGHT ********* <3 ********* */}
                <Accordion>
                  {content8.map(item => {
                    return (
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {item.question}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            {item.answer}
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    )

                  })}
                </Accordion>

              </div>

            </div>
            {/* <div className="row">
              <div className="col-md-4 mb-7 mb-md-0">
                <a href="team.html">
                </a><article className="card article-post bg-dark_A-40 border border-secondary py-6 px-5"><a href="team.html">
                  <div className="position-relative overflow-hidden br-n   article-image ar-1_1 w-70 mx-auto  border border-secondary mb-3" style={{ backgroundImage: 'url(assets/img/avatar/Jeremy_Dunn.jpg)', backgroundSize: '100% 100%' }} />
                </a><figure><a href="team.html">
                </a><figcaption><a href="team.html">
                </a><h5 className="text-light mt-1 mb-0"><a href="team.html" /><a href="#" className="text-light">Jeremy Dunn</a></h5>
                      <div className="social-buttons lead-1">
                          <a className="social-facebook" target="_blank" href="https://www.facebook.com/jeremiah.m.dunn/"><i className="fab fa-facebook" /></a>
                          <a className="social-twitter" target="_blank" href="https://twitter.com/jeremiah_dunn"><i className="fab fa-twitter" /></a>
                          <a className="social-instagram" target="_blank" href="https://www.instagram.com/jeremiahdunn/"><i className="fab fa-instagram" /></a>
                          <a className="social-dribbble" target="_blank" href="  https://www.linkedin.com/in/jeremiahdunn/"><i className="fab fa-linkedin" /></a>
                      </div>
                    
                    </figcaption>
                  </figure>
                </article>
              </div>
              <div className="col-md-4 mb-7 mb-md-0">
                <a href="team.html">
                </a><article className="card article-post bg-dark_A-40 border border-secondary py-6 px-5"><a href="team.html">
                  <div className="position-relative overflow-hidden br-n   article-image ar-1_1 w-70 mx-auto  border border-secondary mb-3" style={{ backgroundImage: 'url(assets/img/oschino_kids.jpg)', backgroundSize: '100% 100%' }} />
                </a><figure><a href="team.html">
                </a><figcaption><a href="team.html">
                </a><h5 className="text-light mt-1 mb-0"><a href="team.html" /><a href="#" className="text-light">Oschino Vasquez</a></h5>
                      <div className="social-buttons lead-1">

                        <a className="social-instagram" target="_blank" href=" https://www.instagram.com/oschinovasquez1/"><i className="fab fa-instagram" /></a>
                        <a className="social-instagram" target="_blank" href=" https://open.spotify.com/artist/59h9wM4IPZZKUcMGiMaCJn?si=IpIPkYg4T16Pav0WrrEXAg"><img src="assets/img/spotify.png" width="20px" style={{"margin-top":"-6px"}} /></a>
                        <a className="social-instagram" target="_blank" href=" https://music.apple.com/us/artist/oschino/2091128"><img src="assets/img/apple-music.png" width="20px" style={{"margin-top":"-6px"}} /></a>
                        <a className="social-dribbble" target="_blank" href="https://www.youtube.com/c/OschinoVasquez"><i className="fab fa-youtube" /></a>

                      </div>
               
                    </figcaption>
                  </figure>
                </article>
              </div>
              <div className="col-md-4">
                <a href="team.html">
                </a><article className="card article-post bg-dark_A-40 border border-secondary py-6 px-5"><a href="team.html">
                  <div className="position-relative overflow-hidden br-n  article-image ar-1_1 w-70 mx-auto  border border-secondary mb-3" style={{ backgroundImage: 'url(assets/img/avatar/team3.jpg)', backgroundSize: '100% 100%' }} />
                </a><figure><a href="team.html">
                </a><figcaption><a href="team.html">
                </a><h5 className="text-light mt-1 mb-0"><a href="team.html" /><a href="#" className="text-light">Redouane Belhadi</a></h5>
                      <div className="social-buttons lead-1">
                      <a className="social-instagram" target="_blank" href="https://music.amazon.com/artists/B09J17N2M6/redouane-belhadi"><img src="assets/img/apple-music.png" width="20px" style={{"margin-top":"-6px"}} /></a>
                      <a className="social-instagram" target="_blank" href="https://www.instagram.com/redouane_belhadi/"><i className="fab fa-instagram" /></a>
                      
                      </div>
                      
                    </figcaption>
                  </figure>
                </article>
              </div>
            </div> */}
          </div>
        </section>
        {/* /.End Content Area */}
      </main>
      <Footer />
    </>
  );


}

export default Home;